import { v4 as uuidv4 } from "uuid";

import { loadAndResizeImageDataURL } from "../../common/components/form/ImageInput";

const { get, post } = require("../../common/utils/api");
const { db } = require("./data");

export function errorMessage(message) {
  return {
    response: {
      data: message,
    },
  };
}

export async function generateThumbnail(dataURI) {
  // Use the existing loadAndResizeImageDataURL function
  const result = await loadAndResizeImageDataURL({
    dataURL: dataURI,
    maxSize: Infinity, // making it infinite to facilitate mocking
  });

  return result.thumbnail;
}

async function process(images, c, name) {
  if (!images || images.length === 0) return;

  for (let i = 0; i < images.length; i++) {
    try {
      const imageId = images[i]?.id;
      const imageObj = await db.images.get(imageId);
      let { image, image_key: imageKey, thumbnail } = imageObj;
      // Check if the image exists on the server
      let exists = false;
      if (imageKey) {
        const {
          data: { exists: e },
        } = await get(`/image/check/?image_key=${imageKey}`);
        exists = e;
      }
      if (!exists) {
        if (!image || typeof image !== "string" || !image.startsWith("data:")) {
          throw errorMessage(
            `Invalid ${name} image #${i + 1} for crop #${c + 1}`,
          );
        }
        if (!imageKey && !thumbnail) {
          const ext = image.split(";base64,")[0].split("/")[1];
          imageKey = `${uuidv4()}.${ext}`;
          imageObj.image_key = imageKey;
          thumbnail = await generateThumbnail(image);
          await db.images.update(imageId, {
            image_key: imageKey,
            thumbnail,
          });
        }

        // Push the image to the server
        await post("/image/push/", { image, image_key: imageKey });
      }
      // Only update the database if the push was successful
      await db.images.update(imageId, { image: undefined });
    } catch (e) {
      if (e?.response?.data.startsWith("Invalid")) {
        throw errorMessage(e.response.data);
      }
      throw errorMessage(
        `Error with ${name} image #${i + 1} for crop #${c + 1}`,
      );
    }
  }
}

export async function processImages(d) {
  // Process all crops and their image types
  for (let c = 0; c < d.crops.length; c++) {
    const crop = d.crops[c];
    try {
      await process(crop.images, c, "crop");
      await process(crop.boundary_images, c, "boundary_crop");
      await process(crop.disease_images, c, "disease");

      // Legacy
      await process(crop.yield_images, c, "yield");
      await process(crop.yield?.yield_images, c, "yield");
      await process(crop.yield?.size_images, c, "size");
      await process(crop.yield?.diagonal_images, c, "diagonal");
      await process(crop.yield?.harvested_images, c, "harvested");
      await process(crop.yield?.moisture_images, c, "moisture");
      await process(crop.yield?.yield_w1_images, c, "yield w1");
      await process(crop.yield?.yield_w2_images, c, "yield w2");
      await process(crop.yield?.yield_w3_images, c, "yield w3");
      await process(crop.yield?.yield_w4_images, c, "yield w4");
      await process(crop.yield?.yield_w4_drying_images, c, "yield w4 drying");
      await process(crop.yield?.yield_w5_images, c, "yield w5");
    } catch (error) {
      throw error;
    }
  }
}

export async function syncImages() {
  const data = await db.data.toArray();
  for (const d of data) {
    await processImages(d);
  }
}

// Utility to traverse and remove specific fields
export function traverseAndRemove(obj) {
  if (!obj || typeof obj !== "object") return;

  if (Array.isArray(obj)) {
    for (const item of obj) {
      traverseAndRemove(item);
    }
  } else {
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        // If the key indicates an image-related array, remove "id" and "thumbnail" from its objects
        if (Array.isArray(obj[key]) && /images$/.test(key)) {
          for (const imageObj of obj[key]) {
            if (typeof imageObj === "object") {
              delete imageObj.id;
              delete imageObj.thumbnail;
            }
          }
        }
        traverseAndRemove(obj[key]); // Continue traversal
      }
    }
  }
}
