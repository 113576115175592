import Dexie from "dexie";
import IDBExportImport from "indexeddb-export-import";

import { loadAndResizeImageDataURL } from "../../common/components/form/ImageInput";

export const db = new Dexie("myDatabase");
db.version(1).stores({
  data: "++id", // Primary key
});
db.version(2).stores({
  images: "++id", // Primary key
});

export function exportDB(cb) {
  db.open().then(function () {
    const idbDatabase = db.backendDB();
    IDBExportImport.exportToJsonString(idbDatabase, cb);
  });
}

export function clearDB(cb) {
  db.open().then(function () {
    const idbDatabase = db.backendDB();
    IDBExportImport.clearDatabase(idbDatabase, cb);
  });
}

export function importDB(jsonString, cb) {
  db.open().then(function () {
    const idbDatabase = db.backendDB();
    IDBExportImport.clearDatabase(idbDatabase, cb);
    IDBExportImport.importFromJsonString(idbDatabase, jsonString, cb);
  });
}

export async function processAllImages(data) {
  async function processImages(images) {
    if (!images) return;
    for (let i = 0; i < images.length; i++) {
      // Put image and geolocation to database and replace with ID
      images[i] = {
        id: await db.images.put(images[i]),
      };
    }
  }

  for (let crop of data.crops) {
    await processImages(crop.images);
    await processImages(crop.boundary_images);
    await processImages(crop.disease_images);
    // Legacy
    await processImages(crop.yield_images);
    // New yield
    await processImages(crop.yield?.yield_images);
    await processImages(crop.yield?.size_images);
    await processImages(crop.yield?.diagonal_images);
    await processImages(crop.yield?.harvested_images);
    await processImages(crop.yield?.moisture_images);
    await processImages(crop.yield?.yield_w1_images);
    await processImages(crop.yield?.yield_w2_images);
    await processImages(crop.yield?.yield_w3_images);
    await processImages(crop.yield?.yield_w4_images);
    await processImages(crop.yield?.yield_w4_drying_images);
    await processImages(crop.yield?.yield_w5_images);
  }
}

export async function loadAllImages(data) {
  async function loadImages(images, maxSize) {
    if (images) {
      var i = 0;
      while (i < images.length) {
        // Load legacy image string as object
        if (typeof images[i] === "string") {
          images[i] = { image: images[i] };
        }

        if (images[i].id) {
          // Load image data from database using linked ID
          images[i] = await db.images.get(images[i].id);
          // Resize image if needed
          if (images[i]?.image && maxSize) {
            images[i].image = await loadAndResizeImageDataURL({
              dataURL: images[i].image,
              maxSize,
            }).then((res) => res.image);
          }
        }

        // Check if image was found
        if (images[i]) {
          i++;
        } else {
          // Remove this image
          images.splice(i, 1);
        }
      }
    }
  }

  if (data.crops) {
    for (var c = 0; c < data.crops.length; c++) {
      const crop = data.crops[c];
      await loadImages(crop.images);
      await loadImages(crop.boundary_images);
      await loadImages(crop.disease_images);

      // Load yield images with new maxSize

      // Legacy
      await loadImages(crop.yield_images, 1000);

      await loadImages(crop.yield?.yield_images, 1000);
      await loadImages(crop.yield?.size_images, 1000);
      await loadImages(crop.yield?.diagonal_images, 1000);
      await loadImages(crop.yield?.harvested_images, 1000);
      await loadImages(crop.yield?.moisture_images, 1000);
      await loadImages(crop.yield?.yield_w1_images, 1000);
      await loadImages(crop.yield?.yield_w2_images, 1000);
      await loadImages(crop.yield?.yield_w3_images, 1000);
      await loadImages(crop.yield?.yield_w4_images, 1000);
      await loadImages(crop.yield?.yield_w4_drying_images, 1000);
      await loadImages(crop.yield?.yield_w5_images, 1000);
    }
  }
}

export async function deleteData(data) {
  // Remove all linked images
  async function deleteImages(images) {
    if (images) {
      for (var i = 0; i < images.length; i++) {
        if (images[i].id) {
          await db.images.delete(images[i].id);
        }
      }
    }
  }
  // Remove data record first so that we don't remove images without deleting
  // the data
  await db.data.delete(data.id);

  if (data.crops) {
    for (var c = 0; c < data.crops.length; c++) {
      const crop = data.crops[c];
      await deleteImages(crop.images);
      await deleteImages(crop.boundary_images);
      await deleteImages(crop.disease_images);
      // Legacy
      await deleteImages(crop.yield_images);

      await deleteImages(crop.yield?.yield_images);
      await deleteImages(crop.yield?.size_images);
      await deleteImages(crop.yield?.diagonal_images);
      await deleteImages(crop.yield?.harvested_images);
      await deleteImages(crop.yield?.moisture_images);
      await deleteImages(crop.yield?.yield_w1_images);
      await deleteImages(crop.yield?.yield_w2_images);
      await deleteImages(crop.yield?.yield_w3_images);
      await deleteImages(crop.yield?.yield_w4_images);
      await deleteImages(crop.yield?.yield_w4_drying_images);
      await deleteImages(crop.yield?.yield_w5_images);
    }
  }

  if ((await db.data.count()) === 0) {
    // All data records have been sent, we can clear the image store to
    // delete any lingering images
    db.images.clear();
  }
}
