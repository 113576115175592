import { useLiveQuery } from "dexie-react-hooks";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { withTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import LoadingModal from "../../common/components/LoadingModal.js";
import Popup from "../../common/components/Popup.js";
import ErrorBoundary from "../../common/utils/helpers.js";
import { makeNullCrop } from "../components/Crop.js";
import { isPhenologyCrop, isYieldCrop } from "../components/CropItem";
import Plot from "../components/Plot.js";
import { db, loadAllImages, processAllImages } from "../utils/data";
import { haversineDistance, parseManualLocation } from "../utils/location.js";

const MIN_VERSION_FIVE_DISEASE_IMAGES = 1087;

export function cleanDataBeforeSubmit(rawValue, t) {
  const value = JSON.parse(JSON.stringify(rawValue));

  const nullCrop = makeNullCrop();
  // Extract manual location into separate fields before submission
  if (value.locationManual) {
    [value.latitude, value.longitude] = parseManualLocation(
      value.locationManual,
    );
    value.accuracy = "";
    value.manualLocation = true;
  }
  // Remove distance field
  delete value.distance;

  value.crops.forEach((entry) => {
    if (entry.crop !== "other" && entry.otherCropType) {
      // Remove other crop type if crop is not other
      entry.otherCropType = nullCrop.otherCropType;
    }
    if (entry.crop !== "preparedland" && entry.preparedCropType) {
      // Remove prepared crop type if crop is not a prepared land
      entry.preparedCropType = nullCrop.preparedCropType;
    }
    if (!isYieldCrop(entry.crop, t)) {
      // Remove yield data if crop is not a yield crop
      entry.yield = nullCrop.yield;
    }
    if (!isPhenologyCrop(entry.crop, t)) {
      // Remove phenology data if crop is not a phenology crop
      entry.phenology = nullCrop.phenology;
    }
    if (entry.yield && entry.yield.recording === "no") {
      // Remove yield data if recording is set to "no"
      const defaultYield = nullCrop.yield;
      entry.yield = { ...defaultYield, recording: entry.yield.recording };
    }
    if (entry.disease === "no") {
      // Remove disease-related data if disease is set to "no"
      if (entry.comment) {
        entry.comment = nullCrop.comment;
      }
      entry.disease_images = nullCrop.disease_images;
    }
  });

  return value;
}
export function validateRecord(values, t) {
  const errors = {};

  const imageRequired = t(
    "common.imageRequired",
    "At least one image is required",
  );
  const fieldRequired = t("crop.requiredField", "Required field");
  const fieldPositive = t("crop.requiredPositive", "Must be greater than zero");

  const makeCropError = (index) => {
    if (!errors.crops) {
      errors.crops = {};
    }
    if (!errors.crops[index]) {
      errors.crops[index] = {};
    }
  };
  const makeYieldError = (index) => {
    makeCropError(index);
    if (!errors.crops[index].yield) {
      errors.crops[index].yield = {};
    }
  };

  const boundaryImage = values.crops?.[0]?.boundary_images?.[0];
  if (values.latitude && values.longitude && boundaryImage?.geolocation) {
    const dist = haversineDistance(
      values.latitude,
      values.longitude,
      boundaryImage.geolocation.latitude,
      boundaryImage.geolocation.longitude,
    );
    if (dist > 10) {
      errors.location = t(
        "plot.locationFarBoundary",
        "The image of the border has been taken too far from the GPS coordinate recorded. Please take pictures and save data at the same place, at least 15m inside the parcel",
      );
    }
  }
  const parcelImage = values.crops?.[0]?.images?.[0];
  if (values.latitude && values.longitude && parcelImage?.geolocation) {
    const dist = haversineDistance(
      values.latitude,
      values.longitude,
      parcelImage.geolocation.latitude,
      parcelImage.geolocation.longitude,
    );
    if (dist > 10) {
      errors.location = t(
        "plot.locationFarParcel",
        "Your position is too far from the location where the picture of the parcel was taken. Please save data 15m deep inside the parcel at the same location where you took the picture of the parcel",
      );
    }
  }

  for (let index = 0; index < values.crops.length; index++) {
    let crop = values.crops[index];

    // Make sure each crop has an image
    if (crop.images.length === 0) {
      makeCropError(index);
      errors.crops[index].images = imageRequired;
    }
    if (crop.boundary_images?.length === 0 && crop.crop !== "bareland") {
      makeCropError(index);
      errors.crops[index].boundary_images = imageRequired;
    }

    // Make sure a disease image is attached if disease is present
    if (crop.disease === "yes") {
      const collectionPipeline = values?.collection_app?.projectPipeline;
      if (
        collectionPipeline &&
        parseInt(collectionPipeline, 10) < MIN_VERSION_FIVE_DISEASE_IMAGES
      ) {
        if (crop.disease_images.length == 0) {
          makeCropError(index);
          errors.crops[index].disease_images = imageRequired;
        }
      } else {
        if (crop.disease_images.length < 5) {
          makeCropError(index);
          errors.crops[index].disease_images = t(
            "common.imageRequiredFive",
            "At least five images are required",
          );
        }
      }
    }

    // Build set of yield fields to validate
    if (isYieldCrop(crop.crop, t) && crop.yield?.recording === "yes") {
      const yield_image_required = ["size_images", "diagonal_images"];
      const yield_field_required = ["size"];
      const yield_field_positive = ["diagonal"];

      // Yield required images
      yield_image_required.forEach((yield_image_field) => {
        if (crop.yield?.[yield_image_field]?.length === 0) {
          makeYieldError(index);
          errors.crops[index].yield[yield_image_field] = imageRequired;
        }
      });

      // Yield required fields
      yield_field_required.forEach((field) => {
        if (!crop.yield?.[field]) {
          makeYieldError(index);
          errors.crops[index].yield[field] = fieldRequired;
        }
      });

      // Yield required positive fields
      yield_field_positive.forEach((field) => {
        if (!crop.yield?.[field] || crop.yield?.[field] <= 0) {
          makeYieldError(index);
          errors.crops[index].yield[field] = fieldPositive;
        }
      });
    }
  }

  return errors;
}

export function DataCollectionForm({ showAlert, t }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupDetails, setPopupDetails] = useState({
    title: "",
    message: "",
    type: "error",
  });

  const initialValues = {
    crops: [makeNullCrop()],
    irrigation: "",
    latitude: "",
    longitude: "",
    accuracy: "",
    distance: "",
    name: "",
    locationManual: "",
    // Generate UUID now so that it is reused if form is somehow saved twice
    uuid: uuidv4(),
  };

  const currentIdFound = location.state ? location.state.id : "";

  const combinedQuery = useLiveQuery(async () => {
    const data = await db.data.reverse().toArray();
    const l = [];
    let currentInfo = {};

    if (data) {
      for (const d of data) {
        if (d.id !== currentIdFound) {
          l.push({ latitude: d.latitude, longitude: d.longitude });
        } else {
          currentInfo = { ...d };
          await loadAllImages(currentInfo);
        }
      }
    }
    return { locationData: l, currentInfo };
  });

  const { locationData, currentInfo } = combinedQuery || {};

  if (currentInfo && Object.keys(currentInfo).length > 0) {
    delete initialValues.distance;
    Object.keys(currentInfo).forEach((k) => {
      initialValues[k] = currentInfo[k];
    });
  }

  async function onSubmit(value, { setSubmitting }) {
    value = cleanDataBeforeSubmit(value, t);
    const app = {
      timestamp: process.env.REACT_APP_CI_COMMIT_TIMESTAMP,
      branch: process.env.REACT_APP_CI_COMMIT_REF_NAME,
      commit: process.env.REACT_APP_CI_COMMIT_SHA,
      pipeline: process.env.REACT_APP_CI_PIPELINE_ID,
      projectPipeline: process.env.REACT_APP_CI_PIPELINE_IID,
    };
    const newData = {
      createddate: new Date(), // Only used if not in currentInfo
      uuid: uuidv4(), // Add UUID to newData if missing (for legacy data)
      ...currentInfo, // currentInfo can override createddate
      ...value, // value can override currentInfo
      updateddate: new Date(), // Always set updateddate to current time
      collection_app: app,
    };

    await processAllImages(newData)
      .then(async () => {
        await db.data
          .put(newData)
          .then(() => {
            showAlert({
              type: "success",
              message: t("common.dataRecorded", "Data recorded"),
            });
            navigate("/data/task");
          })
          .catch((error) => {
            handleFormSaveError(error, t, setPopupDetails, setPopupOpen);
          });
      })
      .catch((error) => {
        handleFormSaveError(error, t, setPopupDetails, setPopupOpen);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  function showErrorMessage(errors, touched) {
    for (const k in errors) {
      if (touched[k]) {
        return true;
      }
    }
    return false;
  }

  return (
    currentInfo && (
      <div className="mx-auto max-w-2xl">
        <Popup
          open={popupOpen}
          setOpen={setPopupOpen}
          title={popupDetails.title}
          message={popupDetails.message}
          type={popupDetails.type}
        />
        <ErrorBoundary>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={(d) => validateRecord(d, t)}
          >
            {({ isSubmitting, touched, errors }) => (
              <>
                <LoadingModal open={isSubmitting} />
                <Form>
                  <Field
                    type="hidden"
                    name="uuid"
                    id="uuid"
                    data-testid="uuid"
                    disabled
                    className="hidden"
                  />
                  <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 bg-white rounded-md shadow-md px-4 sm:px-6 lg:px-8 pb-4 sm:pb-6 lg:pb-8">
                    {locationData && (
                      <Plot
                        showAlert={showAlert}
                        previousLocationData={locationData}
                      />
                    )}
                  </div>
                  <div className="py-5">
                    <div className="flex justify-end">
                      <Link
                        to="/data/task"
                        className={`rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2
                        ${
                          isSubmitting
                            ? "bg-gray-100 cursor-not-allowed"
                            : "bg-white hover:bg-gray-50"
                        }`}
                      >
                        {t("common.cancel", "Cancel")}
                      </Link>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-cyan-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 disabled:bg-gray-500 disabled:cursor-not-allowed"
                      >
                        {t("common.saveForm", "Save Form")}
                      </button>
                    </div>
                  </div>
                  <p
                    className={`text-sm font-medium text-red-800 mt-0 text-right`}
                  >
                    {showErrorMessage(errors, touched) &&
                      t(
                        "common.errorsDetected",
                        "Error(s) detected in form, please correct before saving",
                      )}
                  </p>
                </Form>
              </>
            )}
          </Formik>
        </ErrorBoundary>
      </div>
    )
  );
}

export default withTranslation()(DataCollectionForm);

function handleFormSaveError(error, t, setPopupDetails, setPopupOpen) {
  let title;
  let errorMessage;
  if (error.inner && error.inner.name === "QuotaExceededError") {
    title = t("common.storageQuotaExceeded", "Storage quota exceeded");
    errorMessage = t(
      "common.dataMayBeLost",
      "Your device storage is full. Please submit any existing data points before collecting more.",
    );
  } else {
    title = t("common.errorSavingData", "Error saving data");
    errorMessage = t(
      "common.errorSavingData",
      "Error saving data. Please try again.",
    );
  }

  // Set popup details and open it
  setPopupDetails({
    title,
    message: errorMessage,
    type: "error",
  });
  setPopupOpen(true);
}
